export default {
    methods: {
        getLoggedUser () {
            const loggedUser = JSON.parse(localStorage.getItem('traderProfile'))
            if (loggedUser) {
              return loggedUser
            }
        },
  
        removeLoggedUser() {
          const loggedUser = JSON.parse(localStorage.getItem('traderProfile'))
            if (loggedUser) {
              localStorage.removeItem('traderProfile')
            }
        },
  
        getDate(dateTime) {
          let date = dateTime.split('T')[0]
          let time = dateTime.split('T')[1]
          
         let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  
          const year = date.split('-')[0]
          const month = date.split('-')[1]
          const day = date.split('-')[2]
          const getMonth = months[month-1]
  
          return {
              date: getMonth+ " " + day + ", " + year,
              time: time 
          }
        },
    }
}