<template>
  <v-app>
    <v-snackbar
      v-if="getToast"
      v-model="getToast.show"
      :color="getToast.type"
      top
      right
    >
      {{ getToast.message }}
    </v-snackbar>
    <Navbar v-if="$route.path !== '/login' && $route.path !== '/register'" />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Navbar from '@/components/Navbar'
export default {
  name: 'App',
  components: {
    Navbar
  },
  computed: {
    ...mapGetters(['getToast'])
  },
  created() {
    this.$store.dispatch('fetchUsers')
  },
  data: () => ({
    //
  }),
};
</script>

<style>
th {
  color: #FF9800 !important;
}
tr:hover {
  background: rgb(66, 66, 66) !important;
  color: #FF9800 !important;
}
tr:nth-child(even) {
  background:rgb(71, 71, 71);
}
th span {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #FF9800 !important;
}

th .v-icon::before {
  color: #FF9800 !important;
}

.v-btn {
  font-weight: 400 !important;
}
</style>