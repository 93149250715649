<template>
    <v-app-bar
      app
      color="black"
      dark
    >
      <div class="d-flex align-center">
        <router-link to="/">
          <img src="../assets/logo.png" width="100" alt="Stankevicius">
        </router-link>
        
      </div>

      <div class="menu ml-3" v-if="$route.path !== '/login' && $route.path !== '/register' && $vuetify.breakpoint.mdAndUp">
        <v-btn text class="text-upercase" @click="addModal = !addModal">
          <!-- <v-icon class="pr-2">mdi-file-document</v-icon> -->
           New Trade</v-btn>
        <!-- <a href="/my-trades" class="text-upercase v-btn v-btn--text theme--dark v-size--default">My Trades</a> -->
        <!-- <router-link to="/my-trades" class="text-upercase v-btn v-btn--text theme--dark v-size--default">My Trades</router-link> -->
        <v-btn text class="text-upercase" @click="goToMyTrade">
          <!-- <v-icon class="pr-2">mdi-account</v-icon> -->
           My Trades</v-btn>
        <v-btn text class="text-upercase" @click="getProfile">
          <!-- <v-icon class="pr-2">mdi-account</v-icon> -->
           Profile</v-btn>
        <v-btn text class="text-upercase" @click="logout">
          <!-- <v-icon class="pr-2">mdi-power</v-icon>  -->
          Logout</v-btn>
      </div>

      <v-spacer></v-spacer>

      <v-btn fab v-if="$vuetify.breakpoint.smAndDown && $route.path === '/'" depressed @click="drawer = !drawer" color="black"><v-icon>mdi-menu</v-icon></v-btn>
      
      <v-navigation-drawer color="black" app temporary v-if="$vuetify.breakpoint.smAndDown" absolute v-model="drawer">
        <v-list
          dense
          nav
        >
          <v-list-item>
            <v-list-item-content>
              <img src="../assets/logo.png" width="100" alt="Stankevicius">
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="my-4"></v-divider>
        <v-list nav>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn text class="text-capitalize" @click="addModal = !addModal">
                  <!-- <v-icon class="pr-2">mdi-file-document</v-icon> -->
                   New Trade</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn text class="text-capitalize" @click="getProfile">
                  <!-- <v-icon class="pr-2">mdi-account</v-icon> -->
                   Profile</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn text class="text-capitalize" @click="logout">
                  <!-- <v-icon class="pr-2">mdi-power</v-icon>  -->
                  Logout</v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- Add Dialog -->

        <v-dialog v-model="addModal" persistent max-width="500px">
          <v-card>
            <v-card-text class="pt-3">
              <div class="d-flex justify-space-between">
                <h4>New Trade</h4>
                <v-icon @click="addModal = !addModal">mdi-close</v-icon>
              </div>
              <v-divider class="my-4"></v-divider>
              <v-form ref="addPost" @submit.prevent="savePost">
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="px-1 py-1">
                    <v-select outlined :rules="[v => !!v || 'Action is required']" :items="['Buy', 'Sell']" color="orange" v-model="newPost.action" label="Action"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="px-1 py-1">
                    <v-select outlined :rules="[v => !!v || 'Delivery is required']"  :items="['CIF', 'FOB']" color="orange" v-model="newPost.delivery" label="Delivery"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="px-1 py-1">
                    <v-text-field outlined :rules="[v => !!v || 'Commodity is required']"  label="Commodity" color="orange" v-model="newPost.commodity"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="px-1 py-1">
                    <v-text-field outlined :rules="[v => !!v || 'Price is required']"  label="Price" color="orange" v-model="newPost.price"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="px-1 py-1">
                    <v-text-field outlined :rules="[v => !!v || 'Destination is required']"  label="Destination" color="orange" v-model="newPost.destination"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="px-1 py-1">
                    <v-text-field outlined :rules="[v => !!v || 'Origin is required']"  label="Origin" color="orange" v-model="newPost.origin"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-1 py-1">
                    <v-text-field outlined :rules="[v => !!v || 'Payment Term is required']"  label="Payment Term" color="orange" v-model="newPost.payment_term"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-1 py-1">
                    <v-textarea outlined :rules="[v => (v || '' ).length <= 200 || 'Description must be 200 characters or less']"  label="Description" color="orange" v-model="newPost.description"></v-textarea>
                  </v-col>
                  <v-col cols="12" class="px-1 py-1">
                    <v-btn color="orange" type="submit" block depressed :disabled="createLoading" :loading="createLoading">Add Trade</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog persistent max-width="500px" v-model="profileModal">
          <v-card>
              <v-card-text class="pt-3">
                  <div class="d-flex justify-space-between">
                      <h4>Profile</h4>
                      <v-icon @click="profileModal = !profileModal">mdi-close</v-icon>
                  </div>
                  <v-divider class="my-3 grey-lighten-3"></v-divider>
                  <v-row no-gutters v-if="userProfile">
                      <v-col cols="6">
                          <p><strong class="orange--text">Name</strong> <br> <span>{{userProfile.name}}</span></p>
                      </v-col>
                      <v-col cols="6">
                          <p><strong class="orange--text">Email</strong> <br> <span>{{userProfile.email}}</span></p>
                      </v-col>
                      <v-col cols="6">
                          <p><strong class="orange--text">Status</strong> <br> <span>{{userProfile.status ? 'Active' : 'Blocked'}}</span></p>
                      </v-col>
                      <v-col cols="6">
                          <p><strong class="orange--text">Verified</strong> <br> <span>{{userProfile.verified ? 'Verified' : 'Not Verified'}}</span></p>
                      </v-col>
                  </v-row>
              </v-card-text>
          </v-card>
      </v-dialog>
    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "Navbar",
  computed: {
    ...mapGetters(['getPostCreated'])
  },
  watch: {
    getPostCreated(val) {
      if(val && val == 1) {
        this.createLoading = false
        this.addModal = false
      }
    }
  },
  data() {
    return {
      drawer: false,
      reportModal: false,
      addModal: false,
      profileModal: false,
      userProfile: null,
      createLoading: false,
      newPost: {
        action: null,
        delivery: null,
        commodity: null,
        price: null,
        payment_term: null,
        destination: null,
        origin: null,
        date: null,
        user_id: null,
        traderId: null,
        status: 'Active',
        description: null
      },
    }
  },
  methods: {
    logout() {
      this.removeLoggedUser()
      this.$store.dispatch('signOutRequest')
      this.$router.push('/login')
    },
    getProfile() {
      if(this.getLoggedUser()) {
        this.userProfile = this.getLoggedUser()
      }
      else {
        this.userProfile = null
      }
      this.profileModal = !this.profileModal
    },
    savePost() {
      if(!this.$refs.addPost.validate()) {
        return
      }
      this.newPost.date = new Date().toISOString()
      this.newPost.traderId = this.getLoggedUser().traderId
      this.newPost.user_id = this.getLoggedUser().id
      this.createLoading = true
      
      this.$store.dispatch('createPost', this.newPost)
    },
    goToMyTrade() {
      window.location.href = "/my-trades";
    }
  }
}
</script>