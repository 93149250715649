import firebase from 'firebase'

const state = {
    posts: [],
    isPostCreated: null,
}

const getters = {
    getPosts: (state) => state.posts,
    getPostCreated: state => state.isPostCreated 
}

const actions = {
    // Create Post
    createPost({commit}, payload) {
        commit('setPostCreated', 0)
        firebase.database().ref('posts').push(payload).then(() => {
            commit('setToast', { message: 'Post Created Successfully.', type: 'green', show: true })
            commit('setPostCreated', 1)
        }).catch(err => {
            commit('setToast', { message: err.message, type: 'red', show: true })
        })
    },

    // Fetch Posts
    fetchPosts({commit}) {
        firebase.database().ref('posts').on('value', snapshot => {
            commit('setPosts', snapshot.val())
        })
    },

    // Update Post
    updatePost({commit}, payload) {
        firebase.database().ref('posts').child(payload.id).update(payload).then(() => {
            commit('setToast', { message: 'Post Status Updated Successfully.', type: 'green', show: true })
        })
    }
}

const mutations = {
    setPostCreated: (state, payload) => state.isPostCreated = payload,
    setPosts: (state, payload) => {
        const postArray = []
        for (const post in payload) {
            payload[post].id = post
            postArray.push(payload[post])
        }

        state.posts = postArray
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}