import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/User'
import Post from './modules/Post'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    toast: null
  },
  mutations: {
    setToast: (state, payload) => {
      state.toast = null
      state.toast = payload
    }
  },
  getters: {
    getToast: state => state.toast
  },
  modules: {
    User,
    Post
  }
})
