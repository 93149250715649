import firebase from 'firebase'

const state = {
    isUserCreated: null,
    isReportCreated: null,
    isConnectCreated: null,
    isUserLogged: null,
    users: [],
    isUserLoggedOut: null,
}

const getters = {
    getUserCreated: state => state.isUserCreated,
    getReportCreated: state => state.isReportCreated,
    getConnectCreated: state => state.isConnectCreated,
    getUserLogged: state => state.isUserLogged,
    getUsers: state => state.users,
    getUserLoggedOut: state => state.isUserLoggedOut,
}

const actions = {
    // CREATE USER
  async createUser ({ commit }, payload) {
    commit('setUserCreated', null)
    await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password).then(async (result) => {
        await firebase.database().ref('users').once('value', async snapshot => {
            console.log(snapshot)
            let userList = [];
            const users = snapshot.val()
            for (const user in users) {
                users[user].id = user
                userList.push(users[user])
              }
            console.log(userList)
            userList = userList.sort((a,b) => Number(b.traderId) - Number(a.traderId))
            if(userList && userList.length) {
                const last = userList[0]
                console.log(last)
                payload.traderId = Number(last.traderId) + 1
            }
            else {
                payload.traderId = 1
            }
            await firebase.database().ref('users').child(result.user.uid).set(payload).then(() => {
                commit('setUserCreated', 1)
                commit('setToast', { message: 'Your account has been registered.', type: 'green', show: true })
                return 1
              })
            
        })
      
    }).catch((err) => {
      commit('setUserCreated', 0)
      commit('setToast', { message: err.message, show: true, type: 'red' })
      return 0
    })
  },

   // Login User
   async userLogin ({ commit }, payload) {
    commit('setUserLogged', null)
    await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      .then(() => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            firebase.database().ref('users').child(user.uid)
              .once('value', (snapshot) => {
                if (snapshot.val()) {
                  if (snapshot.val().isDeleted === true) {
                    commit('setUserLogged', 0)
                    commit('setToast', { message: 'Your account has been deleted by Admin.', type: 'red', show: true })
                    return 0
                  }
                  if (snapshot.val().role !== 'admin') {
                    commit('setLoggedUser', {
                      ...snapshot.val(),
                      id: snapshot.key
                    })
                    commit('setUserLogged', 1)
                    commit('setToast', { message: 'Logged in Successfully.', type: 'green', show: true })
                    return 1
                  } else {
                    commit('setToast', { message: 'Admin cannot access this system.', type: 'red', show: true })
                    commit('setUserLogged', 0)
                    return 0
                  }
                } else {
                  commit('setToast', { message: 'Record Not Found', type: 'red', show: true })
                  commit('setUserLogged', 0)
                  return 0
                }
              })
          }
        })
      })
      .catch((error) => {
        commit('setUserLogged', 0)
        commit('setToast', { message: error.message, show: true, type: 'red' })
      })
  },

  // LOGOUT USER
  signOutRequest (context) {
    context.commit('setUserLogout', null)
    firebase.auth().signOut()
      .then(() => {
        context.commit('setUserLogout', 1)
      }).catch(() => {
        context.commit('setUserLogout', 1)
      })
  },

  // Fetch Users
  fetchUsers({commit}) {
    firebase.database().ref('users').on('value', snapshot => {
      commit('setUsers', snapshot.val())
    })
  },

  // Create Report
  createReport({commit}, payload) {
    commit('setReportCreated', null)
    firebase.database().ref('reports').push(payload).then(() => {
      commit('setReportCreated', 1)
      commit('setToast', { message: "Report Submitted Successfully", show: true, type: 'green' })
    }).catch((error) => {
      commit('setReportCreated', 0)
      commit('setToast', { message: error.message, show: true, type: 'red' })
    })
  },

  // Create Connect
  createConnect({commit}, payload) {
    commit('setConnectCreated', null)
    firebase.database().ref('connects').push(payload).then(() => {
      commit('setConnectCreated', 1)
      commit('setToast', { message: "Connect Submitted Successfully", show: true, type: 'green' })
    }).catch((error) => {
      commit('setConnectCreated', 0)
      commit('setToast', { message: error.message, show: true, type: 'red' })
    })
  },
}

const mutations = {
    setUserCreated: (state, payload) => {
        state.isUserCreated = payload
    },
    setReportCreated: (state, payload) => {
      state.isReportCreated = payload
    },
    setConnectCreated: (state, payload) => {
      state.isConnectCreated = payload
    },
    setLoggedUser: (state, payload) => {
        const loggedUser = localStorage.getItem('traderProfile')
        if (loggedUser) {
        localStorage.removeItem('traderProfile')
        }
        localStorage.setItem('traderProfile', JSON.stringify(payload))
        
      },
      setUserLogout: (state, payload) => {
        if (payload === 1) {
            const loggedUser = localStorage.getItem('traderProfile')
            if (loggedUser) {
                localStorage.removeItem('traderProfile')
                state.isUserLoggedOut = payload
            }
        }
      },
      setUserLogged: (state, payload) => {
        state.isUserLogged = payload
      },

      setUsers: (state, payload) => {
        const userArray = []
        for (const user in payload) {
            payload[user].id = user
            userArray.push(payload[user])
        }

        state.users = userArray
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}